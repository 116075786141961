@import "../../theme";
@import "../../mixins";

.form {
    margin: 20px auto 0;
    padding: 10px 10px 72px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @include media($breakpoint2) {
        max-width: 100%;
    }
}

.form-group {
    display: flex;

    &__label {
        @include unstyled();
        @include font();
        font-size: 12px;
        color: $gray;
        transition: .3s;
        pointer-events: none;
        text-align: right;
        padding-top: 10px;
        width: 130px;
        flex-shrink: 0;
        overflow: hidden;
        line-height: 1.2;

        @include media($breakpoint1) {
            font-size: 14px;
        }

        @include media($breakpoint2, down) {
            padding-bottom: 4px;
            left: 16px;
            top: 27px;
        }
    }

    &__values {
        margin-left: 20px;
        max-width: 430px;

        * + * {
            margin-top: 12px;
        }
    }

    &__add {
        margin-top: 20px;
    }
}

.form-group + .form-group,
.form-group + .form-input,
.form-group + .form-field,
.form-input + .form-group,
.form-input + .form-input,
.form-input + .form-field,
.form-field + .form-group,
.form-field + .form-input {
    @include media($breakpoint2) {
        margin-top: 20px;
    }
}

.form-input {
    position: relative;

    &__control {
        @include media($breakpoint2) {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
    }

    &__label {
        @include unstyled();
        @include font();
        font-size: 12px;
        color: $gray;
        transition: .3s;
        pointer-events: none;
        flex-grow: 1;
        text-align: right;
        overflow: hidden;
        line-height: 1.2;

        @include media($breakpoint1) {
            font-size: 14px;
        }

        @include media($breakpoint2, down) {
            padding-bottom: 4px;
            left: 16px;
            top: 27px;
        }
    }
    &__label + &__input {
        margin-left: 20px;
    }

    &__input {
        width: 430px;
        flex-shrink: 0;
        @include font();
        font-size: 12px;
        line-height: 15px;
        color: $dark;
        border: 1px solid $border;
        padding: 8px 15px 6px;
        border-radius: $border-radius-input;
        resize: none;

        &_select {
            padding: 0;

            & > *:first-child{
                border: none;
            }
        }

        @include media($breakpoint1) {
            font-size: 14px;
        }

        @include media($breakpoint2) {
            max-width: 430px;
        }

        &::placeholder {
            color: $gray;
        }

        &:focus {
            outline: none;
            border-color: $blue-dark;
            box-shadow: 0 0 8px rgba(12, 82, 164, 0.25);

            ~ .form-input__label {
                color: $blue-dark;
            }
        }
    }

    &--not-empty {
        .form-input__label {
            @include media($breakpoint2, down) {
                left: 0;
                top: 0;
                font-size: 10px;

                @include media($breakpoint1) {
                    font-size: 12px;
                }
            }
        }
    }
}

.form-search {
    display: flex;
    align-items: center;
    position: relative;

    &__controls {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;

        & > * {
            margin: 0 10px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__input {
        width: 100%;
        max-width: 100%;
        border: 1px solid $border;
        @include font();
        font-size: 12px;
        color: $dark;
        padding: 15px;

        &::placeholder {
            color: $gray;
        }

        &:focus {
            outline: none;
            border-color: $dark;
        }
    }
}

.checkbox {
    display: flex;

    &__field {
        margin-left: 20px;
    }
}

.form-field {
    display: flex;
    align-items: center;

    &._up {
        align-items: flex-start;
    }

    &__label {
        @include unstyled();
        @include font();
        font-size: 12px;
        color: $gray;
        transition: .3s;
        pointer-events: none;
        flex-shrink: 0;
        width: 130px;
        text-align: right;
        overflow: hidden;
        line-height: 1.2;

        @include media($breakpoint1) {
            font-size: 14px;
        }

        @include media($breakpoint2, down) {
            padding-bottom: 4px;
            left: 16px;
            top: 27px;
        }
    }
    &._up &__label {
        padding: 7px 0;
    }

    &__field {
        margin-left: 20px;
        width: 350px;
        flex-grow: 1;

        > * + * {
            margin-top: 12px;
        }
    }

    & + & {
        margin-top: 20px;
    }
}
