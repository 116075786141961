@import "common/common";

body {
    overflow: hidden;
}

#root {
    height: 100vh;
    position: relative;
}

.link-itgro {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    display: inline-flex;
    align-items: center;
    img {
        padding: 10px;
    }
}

.user-list {
    position: absolute;
    top: 44px;
    bottom: 55px;
    left: 0;
    right: 0;
    overflow-y: scroll;
}

::-webkit-scrollbar-button {
    background-repeat: no-repeat;
    width: 6px;
    height: 0;
}

::-webkit-scrollbar-track {
    background-color: #e4ebf2;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background-color: #80c925;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #80c925;
}

::-webkit-resizer {
    background-repeat: no-repeat;
    width: 6px;
    height: 0;
}

::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
}

#root {
    overflow-y: scroll;
}

.field {
    display: flex;
    padding-top: 20px;

    &__name {
        color: red;
    }

    &__value {
        margin-left: 20px;
        color: black;
    }
}

@import "blocks/user-list/user-list";
@import "blocks/wait/wait";
@import "blocks/tabs/tabs";
@import "blocks/form/form";
@import "blocks/alert/alert";
