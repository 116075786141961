.link {
  font: 16px/20px system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Ubuntu,"Helvetica Neue",Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
  color: #333;
  cursor: pointer;
  text-decoration: none;
  line-height: 80%;

  &:hover {
    color: #3f51b5;
    text-decoration: none;
  }
}