@import "../theme";
@import "../mixins";

body {
    @include font();
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 1;
    color: $dark;
}

*, *::before, *::after {
    box-sizing: border-box;
}

.hidden {
    display: none !important;
}

.btn-group {
    position: relative;
    display: flex;
    align-items: center;

    & > .btn {
        margin: 0 5px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &--fixed {
        position: fixed;
        padding: 10px;
        bottom: 0;
        width: 100%;
        background: $white;
        box-shadow: 0 0 5px $white;
        border-top: 1px solid #E4EBF2;
    }

    &--center {
        justify-content: center;
    }

    &--space-between {
        justify-content: space-between;
    }
}

.pseudolink {
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    text-decoration: underline;
    color: #545C67;
}

.unstyled {
    @include unstyled();
}

.dropdown-menu {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: 0 5px 10px rgba(12, 82, 164, 0.25);
    background: $white;
    border-radius: 2px;
    padding: 6px;

    @include media($breakpoint1) {
        right: auto;
        left: 0;
    }

    &:hover {
        display: block;
        z-index: 2;
    }
}

.dropdown-item {
    @include unstyled();
    display: block;
    padding: 6px;
    @include font();
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.03em;
    color: $dark;
    cursor: pointer;
    white-space: nowrap;

    &:hover, &:focus {
        color: $blue-dark;
    }
}

.dropdown-toggle {
    &:hover + .dropdown-menu {
        display: block;
        z-index: 2;
    }
}

.flex-row {
    display: flex;
    align-items: center;
    margin: 0 -10px;

    &__elem {
        padding: 0 10px;
    }
}

.money {
    &__value {
        & input {
            width: 200px;
        }
    }

    &__currency {
        .form-input__input {
            width: 210px;
        }
    }
}

.select-option-label {
    &._grey {
        color: grey;
    }
}

@import "fonts";
@import "status";
@import "btn";
@import "close";
@import "icons";

